<template>
  <v-list-group :value="false" prepend-icon="mdi-poll">
    <template v-slot:activator>
      <v-list-item-title>BI</v-list-item-title>
    </template>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Starlord</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/clients-base">
        <v-list-item-icon>
          <v-icon>mdi-database</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Base de clientes</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Comercial</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/commercial">
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Comercial</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/commercial-2023">
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Comercial 2023</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/marketing">
        <v-list-item-icon>
          <v-icon>mdi-bullhorn-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Marketing</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/partnerships">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Parcerias</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/partnerships-referrals">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Parcerias - Indicantes</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Produto</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/platform-access">
        <v-list-item-icon>
          <v-icon>mdi-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Acessos a plataforma</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/retailer-app">
        <v-list-item-icon>
          <v-icon>mdi-cellphone-text</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Aplicativo Varejista</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Engenharia</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/engineering-jira">
        <v-list-item-icon>
          <v-icon>mdi-laptop</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Engenharia - JIRA</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/product-catalog">
        <v-list-item-icon>
          <v-icon>mdi-glass-mug-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Catálogo de Produtos</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/dags-monitor">
        <v-list-item-icon>
          <v-icon>mdi-clock-check-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Monitoramento de DAGs</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Operações</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/dash-ops">
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Dash OPS</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/erp-integrations">
        <v-list-item-icon>
          <v-icon>mdi-traffic-light-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Integrações ERP</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/support-tickets">
        <v-list-item-icon>
          <v-icon>mdi-account-wrench</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Tickets de Suporte</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/ti-deployment">
        <v-list-item-icon>
          <v-icon>mdi-desktop-classic</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Implantação TI</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>Indústria</v-list-item-title>
      </template>
      <v-list-item to="/external-bi/promotrade-optin">
        <v-list-item-icon>
          <v-icon>mdi-selection-ellipse-arrow-inside</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Pitch Opt-in Promotrade</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/multi-industry-analytics">
        <v-list-item-icon>
          <v-icon>mdi-factory</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Analytics Multi-indústria</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/trade-engagement">
        <v-list-item-icon>
          <v-icon>mdi-chart-bell-curve-cumulative</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Engajamento</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/mercapromo-results">
        <v-list-item-icon>
          <v-icon>mdi-trending-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Resultados Mercapromo</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/base-expansion">
        <v-list-item-icon>
          <v-icon>mdi-chart-line</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Expansão de base</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/mercapromo-conversion">
        <v-list-item-icon>
          <v-icon>mdi-account-convert</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Conversão Mercapromo</v-list-item-content>
      </v-list-item>
      <v-list-item to="/external-bi/mercapromo-cashback">
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Aptos à Cashback</v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-group :value="false" sub-group>
      <template v-slot:activator>
        <v-list-item-title>CS</v-list-item-title>
      </template>

      <v-list-item to="/external-bi/cs-trade-engagement">
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="body-2">Engajamento de Trade por Analista</v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list-group>
</template>

<script>
export default {}
</script>
